import React, { useEffect, useState } from 'react';
import { Form, Col, Button, Row } from 'react-bootstrap';


const MatrixInput = ({
    section,
    name,
    description,
    question, 
    position, 
    nextQuestion, 
    previousQuestion, 
    showInputs
}) => {
    const { text, data } = question;
    const { questions, options: answers } = data;
    return ( 
        <>
            <Row className='justify-content-md-center'>
                <Col md={11} sm={11} lg={11} className='mt-5 col-header mb-3'>
                    <h6 className='section-title'>{name}</h6>
                    <p className='section-description'>{description}</p>
                </Col>
            </Row>
            <Row className='justify-content-md-center matrix-web'>
                <Col md={9} sm={12} lg={9} className="col-question">
                    <h6 className='mb-5'>{text}</h6>
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                            <th scope="col"></th>
                            {answers.length && answers.map((item, pos) => (
                                <th 
                                    key={pos}
                                    className='th-answers'
                                    scope="col"
                                >
                                    {item.text}
                                </th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            {questions.length && questions.map((item, pos) => (
                                <tr key={pos}>
                                    <th scope="row">{item.text}</th>
                                    {showInputs(pos, item.text, item.code, section)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
                <Col className='d-flex col-btns' md={5} sm={12} lg={5}>
                    <Button 
                        className={`${position > 0 ? 'shadow btn-blue-survey' : 'shadow btn-grey-survey'}`}
                        onClick={() => previousQuestion(position)}
                    >
                        <div className='d-flex justify-content-center mt-1'>
                            <p>
                                Atrás
                            </p>
                        </div>
                    </Button>
                    <Button 
                        className='shadow btn-blue-survey'
                        onClick={() => nextQuestion(position)}
                    >
                        <div className='d-flex justify-content-center mt-1'>
                            <p>
                                Siguente
                            </p>
                        </div>
                    </Button>
                </Col>
            </Row>
        </>
    );
}
 
export default MatrixInput;
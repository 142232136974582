export const SURVEYQUESTIONS = {
    questions: [
        {
            section: 'HS',
            code: 'HS001',
            label: '',
            type: 'select',
            questions: [
                '¿Cuántas horas duermes al día?'
            ],
            answers: [
                'Menos de 4 horas (17)',
                'De 4 a 6 horas (9)',
                'De 7 a 8 horas (13)',
                'Más de 8 horas (14)' 
            ],
            condition: {},
            marker: '',
        },
        {
            section: 'HS',
            code: 'HS004',
            label: 'Matriz de Somnolencia durante el día',
            type: 'matrix',
            questions: [
                '¿Tienes un sueño reparador?', 
                '¿Tomas algún medicamento para dormir?', 
                '¿Tienes dificultad para conciliar el sueño y/o te despiertas a medianoche?',
                '¿Experimentas sueño durante el día mientras trabajas?'
            ],
            answers: [
                'Siempre (1)',
                'Muy a menudo (2)',
                'A veces (3)',
                'Rara Vez (4)',
                'Nunca (5)'
            ],
            condition: {},
            marker: 'end',
        }
    ],
};

export const BODY = {
    "_id": "65f2eabd4c9489c566249d13",
    "code": "hra",
    "name": "Encuesta",
    "description": "",
    "sections": [
      {
        "code": "HS00",
        "name": "Higiene del sueño",
        "description": "Es importante conocer tus hábitos y calidad de sueño ya que impactan en la salud física y mental por lo que debes contestar las preguntas de acuerdo a lo que más te identifiques.",
        "questions": [
          {
            "code": "HS01",
            "text": "¿Cuántas horas duermes al día?",
            "type": "SINGLE_CHOICE",
            "data": {
              "options": [
                {
                  "text": "Menos de 4 horas",
                  "code": "HS01-17"
                },
                {
                  "text": "De 4 a 6 horas",
                  "code": "HS01-9"
                },
                {
                  "text": "De 7 a 8 horas",
                  "code": "HS01-13"
                },
                {
                  "text": "Más de 8 horas",
                  "code": "HS01-14"
                }
              ]
            }
          },
          {
            "code": "HS03",
            "text": "En tu opinión, ¿Cómo calificarías tu calidad de sueño? Considera 5 estrellas como la mejor calidad y 1 como la peor (2)",
            "type": "SINGLE_CHOICE",
            "data": {
              "options": [
                {
                  "text": "⭐",
                  "code": "HS03-1"
                },
                {
                  "text": "⭐⭐",
                  "code": "HS03-2"
                },
                {
                  "text": "⭐⭐⭐",
                  "code": "HS03-3"
                },
                {
                  "text": "⭐⭐⭐⭐",
                  "code": "HS03-4"
                },
                {
                  "text": "⭐⭐⭐⭐⭐",
                  "code": "HS03-5"
                }
              ]
            }
          },
          {
            "code": "HS04",
            "text": "Matriz de Somnolencia durante el día",
            "type": "SINGLE_MATRIX_CHOICE",
            "data": {
              "questions": [
                {
                  "text": "¿Tienes un sueño reparador?",
                  "code": "HS04-17"
                },
                {
                  "text": "¿Tomas algún medicamento para dormir?",
                  "code": "HS04-14"
                },
                {
                  "text": "¿Tienes dificultad para conciliar el sueño y/o te despiertas a medianoche?",
                  "code": "HS04-13"
                },
                {
                  "text": "¿Experimentas sueño durante el día mientras trabajas?",
                  "code": "HS04-9"
                }
              ],
              "options": [
                {
                  "text": "Siempre",
                  "code": "HS04-R1"
                },
                {
                  "text": "Muy a menudo",
                  "code": "HS04-R2"
                },
                {
                  "text": "A veces",
                  "code": "HS04-R3"
                },
                {
                  "text": "Rara vez",
                  "code": "HS04-R4"
                },
                {
                  "text": "Nunca",
                  "value": "HS04-R5"
                }
              ]
            }
          }
        ]
      }
    ]
  }
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/global.css';
import RoutesMap from 'routes/RoutesMap';

function App() {
  return (
    <BrowserRouter>
        <RoutesMap />
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import {
    Routes,
    Route,
    useNavigate
} from 'react-router-dom';

//views
import Home from '../views/Home/Home';

const RoutesMap = () => {
    const RoutesWithoutNavBar = ({ children }) => {
        return children;
    }
    return ( 
        <Routes>
            <Route
                path="/"
                element={
                    <RoutesWithoutNavBar>
                        <Home />
                    </RoutesWithoutNavBar>
                }
            />
        </Routes>    
    );
}
 
export default RoutesMap;
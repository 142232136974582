import React from 'react';
import useSurvey from 'hooks/useSurvey/useSurvey';
import StructureMatrixQuestion from 'components/MatrixInput/StructureMatrixQuestion';
import SelectInput from 'components/SelectInput/SelectInput';
import DniTopsearch from 'components/DniTopSearch/DniTopSearch';

const initState = {
    sections: [],
};

const Home = () => {
    const {
        questions,
        currentQuestion,
        nextQuestion,
        previousQuestion,
        state,
        handlerChangeInputSelect,
        handlerChangeInputMatrix
    } = useSurvey(initState);
    const { sections } = state;
    return (
        <>
            <div className='div-center'>
                <DniTopsearch />
            </div>
            <div className='div-center mt-3 special-width'>
                <div>
                    {questions.sections.map((section) => (
                        section.questions.map((question, pos) => (
                            <div 
                                key={question.code} 
                                className={`div-question-${ pos } ${currentQuestion === pos ? 'active-question' : ''}`}
                            >
                                {question.type === 'SINGLE_CHOICE' ? (
                                    <SelectInput 
                                        section={section.code}
                                        name={section.name}
                                        description={section.description}
                                        question={question}
                                        position={pos}
                                        nextQuestion={nextQuestion}
                                        previousQuestion={previousQuestion}
                                        handlerChangeInputSelect={handlerChangeInputSelect}
                                    />
                                ) : (
                                    <StructureMatrixQuestion 
                                        section={section.code}
                                        name={section.name}
                                        description={section.description}
                                        question={question}
                                        position={pos}
                                        nextQuestion={nextQuestion}
                                        previousQuestion={previousQuestion}
                                        handlerChangeInputMatrix={handlerChangeInputMatrix}
                                    />
                                )}
                            </div>
                        ))
                    ))}
                </div>
            </div>
        </>
    );
}
 
export default Home;
import {useEffect, useState} from 'react';
import { SURVEYQUESTIONS, BODY } from './questions';

const useSurvey = (initState) => {
    const [state, setState] = useState(initState);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const { sections } = state;

    useEffect(() => {
        setCurrentQuestion(0)
    }, []);

    const nextQuestion = (current) => {
        console.log(state);
        setCurrentQuestion(current + 1);
    };

    const previousQuestion = current => {
        if (current > 0) {
            setCurrentQuestion(current - 1);
        }
    }

    const findQuestion = (code, question) => (sections.some(item => item.code === code && item.question === question));

    const handlerChangeInputSelect = (response) => e => {
        const { code, section, question } = response;
        let newState = [...sections];
        if (sections.length && findQuestion(code, question)) {
            newState = sections.map((item) => 
                item.code === code && item.question === question ? {...item, answer: e.target.value} : item
            );
        } else {
            newState.push({
                section,
                question,
                answer: e.target.value,
                code,
            });
        }
        setState({
            sections: [...newState],
        });
    }

    const handlerChangeInputMatrix = (response) => e => {
        const { code, question, answer, section } = response;
        let newState = [...sections];
        if (sections.length && findQuestion(code, question)) {
            newState = sections.map((item) => 
                item.code === code && item.question === question ? {...item, answer: answer} : item
            );
        } else {
            newState.push({
                section,
                question,
                answer,
                code,
            });
        }
        setState({
            sections: [...newState],
        });
    }

    return {
        questions: BODY,
        currentQuestion,
        state,
        nextQuestion,
        previousQuestion,
        handlerChangeInputSelect,
        handlerChangeInputMatrix
    };
}
 
export default useSurvey;
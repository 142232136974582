import React, { useEffect, useState } from 'react';
import { Form, Col, Button, Row } from 'react-bootstrap';


const MatrixInputMovil = ({
    section,
    question, 
    position, 
    nextQuestion, 
    previousQuestion,
}) => {
    const { text, data } = question;
    const { questions, options: answers } = data;
    return ( 
        <Row className='justify-content-md-center matrix-movil'>
            <Col md={9} sm={12} lg={9} className="col-question">
                <h6 className='mb-2'>{text}</h6>
            </Col>
            {questions.map((question, pos) => (
                <Col key={pos} className='col-md-12 mb-2 content-matrix-movil'>
                    <Col className='col-md-12 text-center'>
                        <p className='question-label'>{question.text}</p>
                    </Col>
                    <Col className='col-md-12 d-flex justify-content-center col-movil'>
                        {answers.map((item, pos) => (
                            <Form.Check
                                key={pos}
                                className='select-question form-select-movil'
                                label={item.text}
                                // checked={String(question1) === "Si"}
                                // onChange={handlerChange}
                                value={pos}
                                name={question.code}
                                type="radio"
                            />
                        ))}
                    </Col>
                </Col>
            ))}
            <Col className='d-flex col-btns' md={5} sm={12} lg={5}>
                <Button 
                    className={`${position > 0 ? 'shadow btn-blue-survey' : 'shadow btn-grey-survey'}`}
                    onClick={() => previousQuestion(position)}
                >
                    <div className='d-flex justify-content-center mt-1'>
                        <p>
                            Atrás
                        </p>
                    </div>
                </Button>
                <Button 
                    className='shadow btn-blue-survey'
                    onClick={() => nextQuestion(position)}
                >
                    <div className='d-flex justify-content-center mt-1'>
                        <p>
                            Siguente
                        </p>
                    </div>
                </Button>
            </Col>
        </Row>
    );
}
 
export default MatrixInputMovil;
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './dniTopSearch.css';

const DniTopsearch = () => {
    return ( 
        <Form
            // onSubmit={handlerSubmit}
        >
            <Row className="align-items-center justify-content-center mt-5 content-dni">
                <Col sm={3} className="my-1">
                    <Form.Control 
                        id="dni"
                        name="dni"
                        className="inputDni" 
                        // value={dni ? dni : ''}
                        // onChange={handlerChangeDni}
                        placeholder="Ingresa tu rut" 
                    />
                </Col>
                <Col xs="auto" className="">
                    <Button
                        className="btn-dni" 
                        type="submit"
                        >Continuar</Button>
                </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
                <Col sm={3} className="my-1">
                    {/* {errors && errors.dni ? (
                        <p className='error-msg'>
                            {errors.dni}
                        </p>
                    ) : null}
                    {error ? (
                        <p className='error-msg'>
                            {errorMsg}
                        </p>
                    ) : null} */}
                </Col>
            </Row>
        </Form>
    );
}
 
export default DniTopsearch;
import React from 'react';
import { Form, Col, Button, Row } from 'react-bootstrap';

const SelectInput = ({
    section,
    name,
    description,
    question, 
    position, 
    nextQuestion, 
    previousQuestion,
    handlerChangeInputSelect
}) => {
    const { text, data, code } = question;
    return ( 
        <>
            <Row className='justify-content-md-center'>
                <Col md={11} sm={11} lg={11} className='mt-5 col-header mb-3'>
                    <h6 className='section-title'>{name}</h6>
                    <p className='section-description'>{description}</p>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                <Col md={9} sm={12} lg={9} className="col-question">
                    <h6>{text}</h6>
                    {data.options.map((answer, pos) => (
                        <Form.Check
                            key={pos}
                            className='select-question'
                            label={answer.text}
                            // checked={String(question1) === "Si"}
                            onChange={handlerChangeInputSelect({code, section, question: text})}
                            value={answer.text}
                            name={answer.code}
                            type="radio"
                        />
                    ))}
                </Col>
                <Col className='d-flex col-btns' md={5} sm={12} lg={5}>
                    <Button 
                        className={`${position > 0 ? 'shadow btn-blue-survey' : 'shadow btn-grey-survey'}`}
                        onClick={() => previousQuestion(position)}
                    >
                        <div className='d-flex justify-content-center mt-1'>
                            <p>
                                Atrás
                            </p>
                        </div>
                    </Button>
                    <Button 
                        className='shadow btn-blue-survey'
                        onClick={() => nextQuestion(position)}
                    >
                        <div className='d-flex justify-content-center mt-1'>
                            <p>
                                Siguente
                            </p>
                        </div>
                    </Button>
                </Col>
            </Row>
        </>
    );
}
 
export default SelectInput;
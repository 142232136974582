import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import MatrixInput from './MatrixInput';
import MatrixInputMovil from './MatrixInputMovil';
const StructureMatrixQuestion = ({
    section,
    name,
    description,
    question,
    position,
    nextQuestion,
    previousQuestion,
    handlerChangeInputMatrix,
}) => {
    const { data } = question;
    const { options: answers } = data;
    const [totalAnswers, setTotalAnswers] = useState(null);
    useEffect(() => {
        const total = answers.length;
        setTotalAnswers(Array.from({length: total}, (_, i) => i + 1));
    }, []);
    const showInputs = (row, question, code, section) => {
        const totalInputs = (
            <>
                {totalAnswers !== null && totalAnswers.length > 0 && totalAnswers.map((item, pos) => {
                    return (
                        <td key={pos}>
                            <Form.Check
                                key={pos}
                                className='select-question select-qty-matrix'
                                label={''}
                                // checked={String(question1) === "Si"}
                                onChange={handlerChangeInputMatrix({code, question, answer: answers[pos], section})}
                                value={`${answers[pos]}`}
                                name={`${row}`}
                                type="radio"
                            />
                        </td>
                    )
                })}
            </>
        );
        return totalInputs;        
    }
    return ( 
        <>
            <MatrixInput 
                section={section}
                name={name}
                description={description}
                question={question}
                position={position}
                nextQuestion={nextQuestion}
                previousQuestion={previousQuestion}
                showInputs={showInputs}
            />
            <MatrixInputMovil 
                section={section}
                question={question}
                position={position}
                nextQuestion={nextQuestion}
                previousQuestion={previousQuestion}
            />
        </>
    );
}
 
export default StructureMatrixQuestion;